var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bi flex flexC"},[_c('img',{staticClass:"w-full",attrs:{"alt":"crm、erp、saas","src":require("../assets/pic3_1.png")}}),_c('div',{staticClass:"flex itemC rowC p20 bgGray mt1 over-hidden",staticStyle:{"padding":"0 10%"}},[_vm._m(0),_c('div',{staticClass:"tl flex1 pt20 pb20"},[_c('video',{staticClass:"br10 shadow5",attrs:{"width":"450","autoplay":"autoplay","loop":"loop","muted":"muted"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../assets/video1.mp4"),"type":"video/mp4"}})])])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tl flex1 mr20 pr20 transform",attrs:{"transform":"left"}},[_c('p',{staticClass:"f36 fb mt10 mb10 mr10"},[_vm._v("数据表格竟能如此酷炫好看")]),_c('div',{staticClass:"f14 mt20 pt10 tl lh25"},[_vm._v("可以制作各类复杂表格，比如折线图、饼图、柱形图、动态图表等，还可轻松实现酷炫的数据可视化效果，几乎可以迎接任何报表挑战")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex itemC rowC p20 over-hidden",staticStyle:{"padding":"0 10%"}},[_c('div',{staticClass:"tl flex1"},[_c('img',{staticClass:"m20",attrs:{"alt":"定制开发，一对一服务","width":"450","src":require("../assets/pic3_2.png")}})]),_c('div',{staticClass:"tl flex1 ml20 pl20 transform",attrs:{"transform":"right"}},[_c('p',{staticClass:"f36 fb mt10 mb10 mr10"},[_vm._v("数据分析便捷高效")]),_c('div',{staticClass:"f14 mt20 pt10 tl lh25"},[_vm._v("可以对数据报表做排序、过滤、求和、求均值、求方差、分组统计等常用计算操作，直观的发现、预警数据中所隐藏的问题。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex itemC rowC p20 bgGray over-hidden",staticStyle:{"padding":"0 10%"}},[_c('div',{staticClass:"tl flex1 mr20 pr20 transform",attrs:{"transform":"left"}},[_c('p',{staticClass:"f36 fb mt10 mb10 mr10"},[_vm._v("数据分析模板，适用各行各业")]),_c('div',{staticClass:"f14 mt20 pt10 tl lh25"},[_vm._v("拥有海量的常用分析模板，例如公司经营报表、生产报表、财务报表、销售报表、采购和物流表等，无需重复开发，即插即用。")])]),_c('div',{staticClass:"tl flex1"},[_c('img',{staticClass:"m20 br10 shadow5",attrs:{"alt":"定制开发，一对一服务","width":"450","src":require("../assets/pic3_3.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex itemC rowC p20 over-hidden",staticStyle:{"padding":"0 10%"}},[_c('div',{staticClass:"tl flex1"},[_c('img',{staticClass:"m20",attrs:{"alt":"定制开发，一对一服务","width":"450","src":require("../assets/pic3_4.png")}})]),_c('div',{staticClass:"tl flex1 ml20 pl20 transform",attrs:{"transform":"right"}},[_c('p',{staticClass:"f36 fb mt10 mb10 mr10"},[_vm._v("动态图表，实时掌握最新数据")]),_c('div',{staticClass:"f14 mt20 pt10 tl lh25"},[_vm._v("传统Excel无法自动更新展示最新的数据，我们可以实时对接业务数据库，只要后端数据发生变化，前端报表即可实时呈现。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex itemC rowC p20 bgGray over-hidden",staticStyle:{"padding":"0 10%"}},[_c('div',{staticClass:"tl flex1 mr20 pr20 transform",attrs:{"transform":"left"}},[_c('p',{staticClass:"f36 fb mt10 mb10 mr10"},[_vm._v("自动生成新表，告别重复做表")]),_c('div',{staticClass:"f14 mt20 pt10 tl lh25"},[_vm._v("很多用户都有制作日报、周报、月报的重复性报表需求，传统软件面对这样的需求时极大的浪费人力，提高效率，快速更新数据报表。")])]),_c('div',{staticClass:"tl flex1"},[_c('img',{staticClass:"m20 br10 shadow5",attrs:{"alt":"定制开发，一对一服务","width":"450","src":require("../assets/pic3_5.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f24 fb tc br10 shadow5 mb10",staticStyle:{"padding":"70px 100px 50px 100px"}},[_vm._v(" 通用场景/实战应用场景 "),_c('div',{staticClass:"flex mt20"},[_c('div',{staticClass:"flex1 tc f18 p50 textWhite",staticStyle:{"background":"#43ACF7"}},[_vm._v("综合图表分析系统")]),_c('div',{staticClass:"flex1 tc f18 p50 textWhite",staticStyle:{"background":"#FE8380"}},[_vm._v("CEO数据表格管理看板")]),_c('div',{staticClass:"flex1 tc f18 p50 textWhite",staticStyle:{"background":"#F69E6F"}},[_vm._v("多数据源关联分析")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex1 tc f18 p50 textWhite",staticStyle:{"background":"#45D37E"}},[_vm._v("公司经营管理")]),_c('div',{staticClass:"flex1 tc f18 p50 textWhite",staticStyle:{"background":"#E8BC58"}},[_vm._v("财务管理分析")]),_c('div',{staticClass:"flex1 tc f18 p50 textWhite",staticStyle:{"background":"#2BC5C6"}},[_vm._v("销售管理分析")]),_c('div',{staticClass:"flex1 tc f18 p50 textWhite",staticStyle:{"background":"#D384F4"}},[_vm._v("生产管理分析")])])])
}]

export { render, staticRenderFns }