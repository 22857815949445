<template>
  <div class="bi flex flexC">
    <img alt="crm、erp、saas" class="w-full" src="../assets/pic3_1.png">

    <div class="flex itemC rowC p20 bgGray mt1 over-hidden" style="padding: 0 10%;">
      <div class="tl flex1 mr20 pr20 transform" transform="left">
          <p class="f36 fb mt10 mb10 mr10">数据表格竟能如此酷炫好看</p>
          <div class="f14 mt20 pt10 tl lh25">可以制作各类复杂表格，比如折线图、饼图、柱形图、动态图表等，还可轻松实现酷炫的数据可视化效果，几乎可以迎接任何报表挑战</div>
      </div>
      <div class="tl flex1 pt20 pb20">
        <video width="450" class="br10 shadow5" autoplay="autoplay" loop="loop" muted="muted">
          <source src="../assets/video1.mp4" type="video/mp4">
        </video>
      </div>
    </div>

    <div class="flex itemC rowC p20 over-hidden" style="padding: 0 10%;">
      <div class="tl flex1">
        <img alt="定制开发，一对一服务" class="m20 " width="450" src="../assets/pic3_2.png">
      </div>
      <div class="tl flex1 ml20 pl20 transform" transform="right">
          <p class="f36 fb mt10 mb10 mr10">数据分析便捷高效</p>
          <div class="f14 mt20 pt10 tl lh25">可以对数据报表做排序、过滤、求和、求均值、求方差、分组统计等常用计算操作，直观的发现、预警数据中所隐藏的问题。</div>
      </div>
    </div>

    <div class="flex itemC rowC p20 bgGray over-hidden" style="padding: 0 10%;">
      <div class="tl flex1 mr20 pr20 transform" transform="left">
          <p class="f36 fb mt10 mb10 mr10">数据分析模板，适用各行各业</p>
          <div class="f14 mt20 pt10 tl lh25">拥有海量的常用分析模板，例如公司经营报表、生产报表、财务报表、销售报表、采购和物流表等，无需重复开发，即插即用。</div>
      </div>
      <div class="tl flex1">
        <img alt="定制开发，一对一服务" class="m20 br10 shadow5" width="450" src="../assets/pic3_3.png">
      </div>
    </div>

    <div class="flex itemC rowC p20 over-hidden" style="padding: 0 10%;">
      <div class="tl flex1">
        <img alt="定制开发，一对一服务" class="m20 " width="450" src="../assets/pic3_4.png">
      </div>
      <div class="tl flex1 ml20 pl20 transform" transform="right">
          <p class="f36 fb mt10 mb10 mr10">动态图表，实时掌握最新数据</p>
          <div class="f14 mt20 pt10 tl lh25">传统Excel无法自动更新展示最新的数据，我们可以实时对接业务数据库，只要后端数据发生变化，前端报表即可实时呈现。</div>
      </div>
    </div>

    <div class="flex itemC rowC p20 bgGray over-hidden" style="padding: 0 10%;">
      <div class="tl flex1 mr20 pr20 transform" transform="left">
          <p class="f36 fb mt10 mb10 mr10">自动生成新表，告别重复做表</p>
          <div class="f14 mt20 pt10 tl lh25">很多用户都有制作日报、周报、月报的重复性报表需求，传统软件面对这样的需求时极大的浪费人力，提高效率，快速更新数据报表。</div>
      </div>
      <div class="tl flex1">
        <img alt="定制开发，一对一服务" class="m20 br10 shadow5" width="450" src="../assets/pic3_5.png">
      </div>
    </div>

    <div class="f24 fb tc br10 shadow5 mb10" style="padding: 70px 100px 50px 100px;">
      通用场景/实战应用场景
      <div class="flex mt20">
        <div class="flex1 tc f18 p50 textWhite" style="background: #43ACF7;">综合图表分析系统</div>
        <div class="flex1 tc f18 p50 textWhite" style="background: #FE8380;">CEO数据表格管理看板</div>
        <div class="flex1 tc f18 p50 textWhite" style="background: #F69E6F;">多数据源关联分析</div>
      </div>
      <div class="flex">
        <div class="flex1 tc f18 p50 textWhite" style="background: #45D37E;">公司经营管理</div>
        <div class="flex1 tc f18 p50 textWhite" style="background: #E8BC58;">财务管理分析</div>
        <div class="flex1 tc f18 p50 textWhite" style="background: #2BC5C6;">销售管理分析</div>
        <div class="flex1 tc f18 p50 textWhite" style="background: #D384F4;">生产管理分析</div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Bi',
  components: {
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', () => {
        const transformList = document.querySelectorAll('.transform[transform]');
        transformList.forEach(item => {
          if (this.isInViewPort(item)) {
            item.hasAttribute('started') && item.removeAttribute('started')
          } else {
            !item.hasAttribute('started') && item.setAttribute('started', true)
          }
        })
      })
    })
  },
  methods: {
    isInViewPort(el) {
      const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      const offsetTop = el.offsetTop;
      const scollTop = document.documentElement.scrollTop;
      return offsetTop - scollTop <= viewPortHeight - 200
    }
  },
}
</script>
